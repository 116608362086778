
header {
	margin-bottom: 30px;
	.top-section {
		background-color: #000;
	}
	.home & {
		color: white;
		min-height: 420px;
		@media (max-width: $screen-xs-max) {
			min-height: 200px;
		}
		background-size: cover;
	  	background-position: 50% 50%;
		.top-section {
			background-color: rgba(0,0,0,.75);
		}
	}
	a.brand {
		color: white;
		font-size: 48px;
		text-decoration: none;
		border: none;
		padding-left: 55px;
		position: relative;
		@media (max-width: $screen-xs-max) {
			font-size: 24px;
			line-height: 40px;
			margin-left: 15px;
			padding-left: 32px;
		}
		&:before {
			content: "";
			position: absolute;
			top: 6px;
			left: 0;
			width: 39px;
			height: 46px;
			background-image: url(../images/logo-icon.png);
			@media (max-width: $screen-xs-max) {
				top: 0px;
				background-repeat: no-repeat;
			    background-size: 59%;
			}
		}
	}
	h3 {
		margin-bottom: 5px;
	}
	p.subtitle {
		color: #fff;
		@media (max-width: $screen-sm-max) {
			display: none;
		}
	}
	.icon-list {
		font-size: 38px;
		line-height: 38px;
	}
}

.navbar-container {
	@media (max-width: $screen-sm-max) {
		float: none !important;
	}
}
.navbar-toggle {
	margin-top: 0;
	margin-bottom: 0;
}
.main-menu {
	margin-top: -15px;
	margin-bottom: 0;
	a {
		color: #fff;
	}
	.nav>li>a:focus, .nav>li>a:hover {
		background-color: rgba(0,0,0,.2);
	}
}
.header-background {
	.background-img {
		padding-top: 25%;
		width: 100%;
		background-size: contain;
		background-position: 50% 50%;
		@media (min-width: $screen-md-min) {
			height: 200px;
			padding-top: 0;
		}
	}
}

.mlp-language-box {
	margin-top: 15px;
	.top-section & {
		@media (min-width: $screen-sm-min) {
			float: left;
			margin-left: 10px;
		}
	}
	ul {
		list-style-type: none;
		padding-left: 0;
		li {
			display: inline-block;
			margin-left: 5px;
			a {
			    padding: 3px;
			    display: block;
			    line-height: 0;
			    border: none;
			    &.current-language-item {
					border-bottom: 1px solid #fff;
					cursor: default;
			    }
			}
		}
	}
}