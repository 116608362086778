.soundcloud-iframe {
	iframe {
		width: 100%;
		height: 200px;
		margin-bottom: 20px;
		border: none;
		background-color: #ccc;
	}
	iframe + br {
		display: none;
	}
}
.soundcloud-more {
	width: 100%;
}
.entry-content > .entry-content-asset {
	overflow: hidden;
}

section.darken {
	position: relative;
	background-size: cover;
	background-position: 50% 50%;
	padding-top: 30px;
	color: #fff;
	&:before {
		content: "";
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		background-color: rgba(0,0,0,.5);
	}
}