.woocommerce-page {
	header time, header .author, .product_meta {
		display: none;
	}
	.quantity, .checkout-button {
		br {
			display: none;
		}
	}
	.quantity .input-text {
		height: 30px;
	}
}
.home-products {
	padding-bottom: 0;
	.woocommerce ul.products {
		margin-bottom: 0;
	}
}