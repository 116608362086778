a {
	border-bottom: 1px dotted rgba(0,0,0,0.5);
}
section {
	/*padding-top: $section-padding-vertical;*/
	padding-bottom: $section-padding-vertical;
	&.blue {
		background-color: $section-background-color;
		color: white;
		a.brand {
			color: white;
		}
	}
	&.salestypes {
		.btn-default {
			width: 100%;
		}
	}
	@media (max-width: $screen-xs-max) {
		&.header {
			.col-sm-4 {
				text-align: center;
			}
			.nav-pills>li {
				float: none;
			}
		}
	}
}

.center {
	text-align: center;
}

@media (max-width: $screen-xs-max) {
	.pull-down {
		margin-top: 0 !important;
	}
}

h1, .h1,
h2, .h2,
h3, .h3,
h4, .h4,
h5, .h5,
h6, .h6 {
  margin-bottom: $line-height-computed;
  margin-top: $line-height-computed / 2;
}


/* language menu */
.nav>li.wpml-ls-item>a {
  padding-left: 5px;
  padding-right: 5px;
}