@font-face {
  font-family: 'miss-m';
  src:url('../fonts/miss-m.eot?pq8d7m');
  src:url('../fonts/miss-m.eot?#iefixpq8d7m') format('embedded-opentype'),
    url('../fonts/miss-m.woff?pq8d7m') format('woff'),
    url('../fonts/miss-m.ttf?pq8d7m') format('truetype'),
    url('../fonts/miss-m.svg?pq8d7m#miss-m') format('svg');
  font-weight: normal;
  font-style: normal;
}

[class^="icon-"]:before, [class*=" icon-"]:before, [class^="icon-"]:after, [class*=" icon-"]:after  {
  font-family: 'miss-m';
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-calendar:before {
  content: "\e600";
}
.icon-bubble:before {
  content: "\e601";
}
.icon-facebook:before {
  content: "\e602";
}
.icon-twitter:before {
  content: "\e603";
}
.icon-feed:before {
  content: "\e604";
}
.icon-plus:before {
  content: "\e605";
}
.icon-instagram:before {
  content: "\e606";
}
.icon-pinterest:before {
  content: "\e607";
}
.icon-list:before {
  content: "\e608";
}
.icon-search:before {
  content: "\e609";
}
.icon-arrow-right:after {
  content: "\e60a";
}
.icon-arrow-left:before {
  content: "\e60b";
}
