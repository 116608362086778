.wpcf7-form {
	input[type="text"], input[type="email"], input[type="tel"], textarea {
		width: 100%;
	    padding: 5px 10px;
	    border: none;
	    border-radius: 3px;
	    color: #000;
	}
	textarea {
		height: 100px;
	}
	input[type="submit"] {
		padding: 5px 30px;
		background: #000;
		border-radius: 3px;
		border: none;
	}
	p:last-of-type {
		margin-bottom: 0;
	}
}